import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
// components.
import { Header, Footer } from 'src/components/layout'
import Button from 'src/components/button'
import Heading from 'src/components/heading/Heading'
import PageHead from 'src/components/head/PageHead'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'
import useRellax from 'src/js/utils/hooks/useRellax'
import { useAssets } from 'js/utils/hooks/useAssetsBase'
// data.
import AboutData from 'src/data/pages/Contents/about.json'

// Styles & Images
import 'static/about/scss/styles.scss'

const images = {
  headerSection: {
    aspectRatio: 1.8,
    path: '/about/img/',
    fileType: 'jpg',
    webp: true,
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1536,
      },
      {
        fileName: 'header-bg-lsize',
        width: 3200,
      },
    ],
  },
}

const iconSVG = (icon, title) => {
  switch (icon) {
    case 'ExodusLogo':
      return (
        <svg version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" opacity=".2">
            <path
              d="M19.8802 5.5843L11.3633 0V3.12221L16.827 6.67261L16.1842 8.70651H11.3633V11.2935H16.1842L16.827 13.3274L11.3633 16.8778V20L19.8802 14.4335L18.4875 10.0089L19.8802 5.5843Z"
              fill="#1A1D40"
            />
            <path
              d="M3.95338 11.2935H8.75643V8.70651H3.93553L3.3106 6.67261L8.75643 3.12221V0L0.239502 5.5843L1.63221 10.0089L0.239502 14.4335L8.77429 20V16.8778L3.3106 13.3274L3.95338 11.2935Z"
              fill="#1A1D40"
            />
          </g>
        </svg>
      )
    case 'Info':
      return (
        <svg
          className="x-info__icn"
          version="1.1"
          viewBox="0 0 18 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd" opacity=".3">
            <g fill="#fff">
              <path d="m14 7l4 5h-15v-5h11z" />
              <polygon points="14 7 3 7 3 2 18 2" opacity=".5" />
              <rect width="1" height="24" />
            </g>
          </g>
        </svg>
      )
    case 'GitHub':
      return (
        <svg viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>{title}</title>
          <path
            d="M9.5,0 C4.25125,0 0,4.36162341 0,9.74120247 C0,14.0460022 2.72175,17.6965178 6.495625,18.9831683 C6.970625,19.074898 7.14479167,18.7737325 7.14479167,18.5147788 C7.14479167,18.2834253 7.136875,17.6705413 7.13291667,16.8587744 C4.49033333,17.4464936 3.933,15.5518297 3.933,15.5518297 C3.50075,14.4275326 2.876125,14.1271789 2.876125,14.1271789 C2.01558333,13.5232243 2.942625,13.5354008 2.942625,13.5354008 C3.89658333,13.6035893 4.39770833,14.5387447 4.39770833,14.5387447 C5.24479167,16.0283369 6.6215,15.5981005 7.16458333,15.348888 C7.25008333,14.7189569 7.49470833,14.2895323 7.76625,14.0460022 C5.65645833,13.8024721 3.439,12.9647287 3.439,9.23222464 C3.439,8.16881004 3.807125,7.30021949 4.41670833,6.61833531 C4.30983333,6.37236995 3.98920833,5.38201437 4.49983333,4.04016372 C4.49983333,4.04016372 5.29545833,3.77877479 7.11233333,5.03863698 C7.87233333,4.82189522 8.67983333,4.714742 9.48733333,4.70987139 C10.2948333,4.714742 11.1023333,4.82189522 11.8623333,5.03863698 C13.6673333,3.77877479 14.4629583,4.04016372 14.4629583,4.04016372 C14.9735833,5.38201437 14.6529583,6.37236995 14.5579583,6.61833531 C15.1635833,7.30021949 15.5317083,8.16881004 15.5317083,9.23222464 C15.5317083,12.9744699 13.3110833,13.7984133 11.1973333,14.0378845 C11.5298333,14.3301206 11.8385833,14.927581 11.8385833,15.840007 C11.8385833,17.1437046 11.8267083,18.1908838 11.8267083,18.5074729 C11.8267083,18.7631795 11.9929583,19.0675921 12.4798333,18.97018 C16.280625,17.692459 19,14.0395081 19,9.74120247 C19,4.36162341 14.746375,4.9568325e-16 9.5,4.9568325e-16"
            fillRule="nonzero"
          />
        </svg>
      )
    case 'Twitter':
      return (
        <svg viewBox="0 0 23 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>{title}</title>
          <path d="M.056 0l8.88 11.873L0 21.527h2.011l7.824-8.452 6.32 8.452H23L13.62 8.986 21.938 0h-2.011l-7.205 7.784L6.9 0H.056zm2.958 1.481h3.144l13.884 18.564h-3.144L3.014 1.481z" />
        </svg>
      )
    default:
      return null
  }
}

// Partials:
const HeaderSection = ({ data }) => {
  useRellax({ className: 'about-rellax' })

  return (
    <header className="x-header">
      <div className="x-header__darken" id="x-header__darken" />
      <div className="x-header__bg-container about-rellax" data-rellax-speed="-4">
        <Img
          fluid={buildImageObject(images.headerSection)}
          durationFadeIn={2000}
          className="x__header-bg"
          alt={data.imageAlt}
        />
      </div>
      <div className="x-header__content">
        <div className="x-header__txt-container x__width">
          <h1 className="x-header__heading" dangerouslySetInnerHTML={{ __html: data.h1 }} />
        </div>
      </div>
    </header>
  )
}

const MissionSection = ({ data }) => (
  <section className="x-mission">
    <div className="x__width">
      {iconSVG('ExodusLogo')}

      <span className="x-about-subheading">{data.subheading}</span>
      <p className="x-mission__heading">{data.heading}</p>

      <div className="row">
        {data.description.map((description, d) => {
          return (
            <div key={d} className="col-md-6 x-mission__txt">
              {description.item}
            </div>
          )
        })}
      </div>

      {data.cta.copy && (
        <div className="x-mission__actions">
          <Button to={data.cta.path} target="_blank" copy={data.cta.copy} size="largest" />
        </div>
      )}
    </div>
  </section>
)

const FeaturesSection = ({ data }) => (
  <section className="x-features">
    <div className="x__width">
      <div className="row">
        {data.map((feature, f) => {
          return (
            <div key={f} className="col-md-4">
              <span className="x-features__subheading">{feature.description}</span>
              <span className="x-features__heading">{feature.title}</span>
            </div>
          )
        })}
      </div>
    </div>
  </section>
)

const InfoSection = ({ data, assets }) => (
  <section className="x-info">
    <div className="x__width">
      {iconSVG('Info')}

      <div className="row">
        {data.map(({ title, subtitle, description }, i) => (
          <div key={i} className="col col-12 col-lg-3">
            <span className="x-info__subheading">{subtitle}</span>
            <span className="x-info__heading">{title}</span>
            <span className="x-info__txt" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        ))}
      </div>
    </div>
  </section>
)

const LeadershipSection = ({ data }) => (
  <section className="x-leadership">
    <div className="x__width">
      <div className="x-leadership__txt">
        <Heading type="h2" page="about" section="leadership" className="x-h2" />
        <p className="x-p" dangerouslySetInnerHTML={{ __html: data.description }} />
      </div>

      <div className="x-leadership-founder-container">
        {data.founders.map((founder) => {
          return (
            <div key={founder.name} className="x-leadership-founder">
              <img src={founder.profileImagePath} alt={founder.name} />
              <span className="x-leadership-founder__cont">
                <span className="x-leadership-founder__name">{founder.name}</span>

                <span className="x-leadership-founder__bio">
                  <span className="x-leadership-founder__title">{founder.title}</span>
                  {founder.bio}
                </span>

                <span>
                  {founder.socialLinks.map((link) => {
                    return (
                      <a
                        className="x-leadership-founder__link"
                        key={link.name}
                        href={link.path}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {iconSVG(link.icon, link.name)}
                      </a>
                    )
                  })}
                </span>
              </span>
            </div>
          )
        })}
      </div>
    </div>
  </section>
)

const GoalSection = ({ data }) => (
  <section className="x-goal">
    <div className="x__width">
      <span className="x-about-subheading">{data.subheading}</span>
      <p className="x-goal__heading">{data.heading}</p>
    </div>
  </section>
)

const CareersSection = ({ data }) => (
  <section className="x-work">
    <div className="x-work__img" role="presentation" />

    <div className="x-work__txt">
      <span className="x-about-subheading">{data.subheading}</span>
      <Heading type="h2" page="about" section="work" className="x-h2" />
      <p className="x-p">{data.description}</p>
      {data.careersLinks.map((link) => {
        return (
          <Link key={link.copy} to={link.path} className="x__button x__button--shadow-light">
            <span className="x__button-align">
              <span className="x__button-align-text">{link.copy}</span>
            </span>
          </Link>
        )
      })}
    </div>
  </section>
)

// Main component:
const AboutPage = () => {
  return (
    <div className="x-page-about">
      <Header />

      <main className="x">
        <HeaderSection data={AboutData.header} />
        <MissionSection data={AboutData.mission} />
        <FeaturesSection data={AboutData.features} />
        <InfoSection data={AboutData.info} assets={useAssets()} />
        <LeadershipSection data={AboutData.leadership} />
        <GoalSection data={AboutData.goal} />
        <CareersSection data={AboutData.careers} />
        <Footer />
      </main>
    </div>
  )
}

export default AboutPage

// <head> component:
export function Head() {
  return <PageHead page="about" />
}
